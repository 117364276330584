import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/worker/code/src/templates/blog-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`In keeping with PayPerks company tradition, today we commemorated the formalization of some exciting new investor relationships with our second PayPerks jump in the `}<a parentName="p" {...{
        "href": "http://en.wikipedia.org/wiki/Washington_Square_Park"
      }}>{`Washington Square Park`}</a>{` fountain.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1000px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "72.75%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAwACBP/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAHrAAg7Uf/EABsQAAICAwEAAAAAAAAAAAAAAAERAgMAEiEj/9oACAEBAAEFAgfKywsxse5lF9Yz/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQIBAT8BR//EABoQAQABBQAAAAAAAAAAAAAAAABBARESUXH/2gAIAQEABj8C0rghxdL/xAAaEAADAQEBAQAAAAAAAAAAAAAAARExQSFR/9oACAEBAAE/IUqaqGKvkLvofJSxOCS0erIO2j//2gAMAwEAAgADAAAAEMTP/8QAFhEBAQEAAAAAAAAAAAAAAAAAAQAR/9oACAEDAQE/EBy0v//EABYRAQEBAAAAAAAAAAAAAAAAAAEQEf/aAAgBAgEBPxBNj//EABwQAQADAAMBAQAAAAAAAAAAAAEAETEhQXGB0f/aAAgBAQABPxAUkZHPCPApVry5z5kQurfN/kqKdARZY0DQBWVOgfs//9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["https://cdn.payperks.com/payperks-corporate/1.16.1/static/15737be9a0400d8f71a653c24dc796f8/7f61c/IMG_6955_touched.webp 400w", "https://cdn.payperks.com/payperks-corporate/1.16.1/static/15737be9a0400d8f71a653c24dc796f8/d00b9/IMG_6955_touched.webp 800w", "https://cdn.payperks.com/payperks-corporate/1.16.1/static/15737be9a0400d8f71a653c24dc796f8/a5d4d/IMG_6955_touched.webp 1000w"],
            "sizes": "(max-width: 1000px) 100vw, 1000px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["https://cdn.payperks.com/payperks-corporate/1.16.1/static/15737be9a0400d8f71a653c24dc796f8/066f9/IMG_6955_touched.jpg 400w", "https://cdn.payperks.com/payperks-corporate/1.16.1/static/15737be9a0400d8f71a653c24dc796f8/4b190/IMG_6955_touched.jpg 800w", "https://cdn.payperks.com/payperks-corporate/1.16.1/static/15737be9a0400d8f71a653c24dc796f8/a2510/IMG_6955_touched.jpg 1000w"],
            "sizes": "(max-width: 1000px) 100vw, 1000px",
            "type": "image/jpeg"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "https://cdn.payperks.com/payperks-corporate/1.16.1/static/15737be9a0400d8f71a653c24dc796f8/a2510/IMG_6955_touched.jpg",
            "alt": "IMG 6955 touched",
            "title": "IMG 6955 touched",
            "loading": "lazy",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>
    <p>{`Isn’t that freezing? Is that even legal?`}</p>
    <p>{`Yes and yes. And thanks for asking. But despite the temperature, we are looking forward to jump number three!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      